import React from 'react';
import {
  StyledOtherServices,
  StyledSubtitle,
  StyledOtherServicesWrapper,
  StyledOtherServiceWrapper,
  StyledOtherServiceBody
} from './styled';
import MarkdownPreview from '@uiw/react-markdown-preview';

const OtherServices = ({ subtitle, services, children }) => {
  return (
    <StyledOtherServices>
      {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}

      <StyledOtherServicesWrapper>
        {services.map((service, index) => {
          const { title, description, order, altIcon } = service;

          return (
            <StyledOtherServiceWrapper id={`anchorService${order}`}>
              <img src={altIcon.url} alt={altIcon.alt} key={index} />

              <StyledOtherServiceBody>
                <div id="service-title">{title}</div>

                <MarkdownPreview source={description} />

                {children}
              </StyledOtherServiceBody>
            </StyledOtherServiceWrapper>
          );
        })}
      </StyledOtherServicesWrapper>
    </StyledOtherServices>
  );
};

export default OtherServices;
