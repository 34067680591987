import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledOtherServices = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSubtitle = styled.h3`
  position: relative;
  margin: 0 0 1rem 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 600;
  color: ${colors.gray300};
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledOtherServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const StyledOtherServiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3rem;

  img {
    align-self: start;
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    height: auto;
    margin: 0;

    @media screen and (max-width: 980px) {
      display: none;
    }
  }
`;

export const StyledOtherServiceBody = styled.div`
  row-gap: 1rem;
  display: flex;
  flex-direction: column;

  #service-title {
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: 700;
    color: ${colors.gray300};

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: ${colors.red400};
      content: '';
    }

    @media screen and (max-width: 980px) {
      font-size: 16px;
      font-weight: 600;
    }
  }

  p {
    @media screen and (max-width: 980px) {
      font-size: 14px;
    }

    a {
      color: ${colors.red300};
    }
  }
`;
